.tab-bar::-webkit-scrollbar {
  display: none;
}

.tab-bar {
  scrollbar-width: none;
}

.App {
  width: 100%;
  height: 100%;
  position: absolute;
}

.title-bar {
  font-weight: 700;
  text-align: center;
  height: 32px;
  line-height: 32px;
}

.title-bar .date-time {
  position: absolute;
  right: 15px;
}

.main-window {
  position: absolute;
  height: calc(100% - 32px);
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

.panel-title {
  text-align: center;
  margin-top: 12px;
}

.panel-title .svg-inline--fa {
  margin-right: 10px;
}

.projects-panel {
  float: left;
  width: 500px;
  height: 100%;
  border: 1px solid;
  box-sizing: border-box;
  position: relative;
}

.project-list {
  position: absolute;
  top: 45px;
  height: calc(100% - 45px);
  left: 15px;
  width: calc(100% - 15px);
  box-sizing: border-box;
  border-left: 1px solid;
  border-top: 1px solid;
  overflow: auto;
}

.project-list .project {
  padding: 20px;
  border-bottom: 1px solid;
  position: relative;
  cursor: pointer;
}

.project-list .project:hover .project-name {
  text-decoration: underline;
}

.project-list .project-subtitle {
  font-size: 0.8em;
}

.project-tags {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  right: 20px;
}

.tag {
  font-size: 0.7em;
  border: 1px solid;
  border-radius: 5px;
  margin: 4px;
  padding: 2px 5px;
  display: inline-block;
}

.project-list .project-details {
  margin-left: 45px;
}

.project-list .folder-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.tabs-panel {
  float: left;
  width: calc(100% - 500px);
  position: relative;
  height: 100%;
}

.tab-bar {
  position: relative;
  z-index: 10;
  white-space: nowrap;
  overflow: auto;
}

.tab-bar::after {
  content: "";
  display: table;
  clear: both;
}

.tab-bar .tab {
  border-top: 1px solid;
  border-right: 1px solid;
  box-sizing: border-box;
  padding: 0 50px;
  line-height: 45px;
  height: 46px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.tab-content {
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-right: 1px solid;
  position: absolute;
  top: 46px;
  width: 100%;
  height: calc(100% - 46px);
  box-sizing: border-box;
  padding: 40px 50px;
  overflow: auto;
}

.tab-bar .tab.selected {
  border-bottom: 4px solid;
  height: 47px;
}

.tab-close {
  position: absolute;
  right: 15px;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%) scaleY(0.8);
  -webkit-transform: translateY(-50%) scaleY(0.8);
  padding: 3px 5px;
  box-sizing: border-box;
  border-radius: 3px;
}

.tab-close:hover {
  border: 1px solid;
  margin-right: -1px;
}

.tab-bar .tab:not(.selected):not(:hover) .tab-close {
  display: none;
}

p:first-child {
  margin-top: 0;
}

.tab-content * {
  max-width: 900px;
}

.button-bar {
  border: 1px solid;
  border-radius: 14px;
  float: left;
}

.button-bar a {
  padding: 0.75em 1.5em;
  display: inline-block;
  cursor: pointer;
  color: rgb(191, 189, 182);
  text-decoration: none;
}

.button-bar a:hover {
  text-decoration: underline;
}

.button-bar a:not(:last-child) {
  border-right: 1px solid;
}

.button-bar-row::after {
  content: "";
  display: table;
  clear: both;
}

.button-bar-row {
  margin: 30px 0;
}

.button-bar-row:last-child {
  margin-bottom: 0;
}

.layout-box {
  border: 1px solid;
  padding: 30px;
  padding-top: 0;
  margin-top: 60px;
  box-sizing: border-box;
}

.layout-box::after {
  content: "";
  display: table;
  clear: both;
}

.panel-content {
  margin-top: 20px;
}

input[type="text"],
input[type="email"],
textarea {
  border: 1px solid;
  border-radius: 4px;
  padding: 15px 20px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 30px 0;
  font-family: "Inconsolata", monospace;
  font-size: 1em;
}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  background-color: rgb(13, 16, 23);
  z-index: 100;
}

.modal .layout-box {
  margin-top: 0;
}

.dim {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0.5;
  z-index: 10;
  max-width: none;
}

.modal .button-bar-row {
  text-align: center;
}

.modal .button-bar {
  float: none;
  display: inline-block;
}

span.error {
  display: block;
  text-align: center;
  color: #ff0000;
}

span.success {
  display: block;
  text-align: center;
  color: #aad94c;
}

.media-gallery-item {
  float: left;
  width: calc(25% - 16px);
  padding-bottom: 23%;
  background-position: center;
  background-size: cover;
  margin: 8px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 5px 5px 0 #34394b;
  opacity: 0.9;
  position: relative;
}

.media-gallery-item:hover {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 6px;
  margin-right: 6px;
  box-shadow: 2px 2px 0 #34394b;
  opacity: 1;
}

.modal.lightbox {
  position: fixed;
}

.lightbox .panel-title {
  position: relative;
  height: 10px;
}

.lightbox:not(.vertical) {
  width: 100%;
  max-width: 1600px;
}

.lightbox.vertical {
  height: 90%;
  width: auto !important;
  max-width: none !important;
}

.lightbox .panel-title .tab-close {
  right: -24px;
  cursor: pointer;
}

.lightbox * {
  max-width: none;
}

.lightbox:not(.vertical) img {
  width: 100%;
}

.lightbox.vertical img {
  height: calc(100vh * 0.9 - 80px);
}

.lightbox.video {
  max-width: 1024px;
}

.lightbox.video iframe {
  width: 100%;
  height: 600px;
}

@media screen and (max-width: 1024px) {
  .lightbox.lightbox.video iframe {
    height: 57vw;
  }
}

.tab-content p {
  text-transform: none;
}

.tab-content ul {
  padding-left: 0;
}

.tab-content ul li {
  margin: 25px 0;
  border: 1px solid #222a31;
  border-radius: 14px;
  padding: 14px;
  list-style: none;
  background: #10141e;
  padding-left: 70px;
  position: relative;
}

.tab-content ul li svg {
  position: absolute;
  left: 23px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.play-icon::before {
  content: "";
  display: block;
  position: absolute;
  width: 80%;
  height: 60%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: #fff;
}

.play-icon svg {
  position: relative;
}

@media screen and (max-width: 1600px) {
  body {
    font-size: 16px;
  }
}

@media screen and (max-width: 1600px) {
  .projects-panel {
    width: 440px;
  }
  .tabs-panel {
    width: calc(100% - 440px);
  }
  .project-list .folder-icon {
    width: 24px;
  }
  .project-list .project-details {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1440px) {
  .tab-bar .tab {
    padding: 0 38px;
  }

  .tab-close {
    right: 10px;
  }
  .tab-content {
    padding: 40px 38px;
  }
  .projects-panel {
    width: 420px;
  }
  .tabs-panel {
    width: calc(100% - 420px);
  }
  input[type="text"],
  input[type="email"],
  textarea {
    padding: 10px 15px;
    margin: 20px 0;
  }
}

@media screen and (max-width: 1366px) {
  .tab-bar .tab {
    padding: 0 28px;
  }
  body {
    font-size: 14px;
  }
  .projects-panel {
    width: 375px;
  }
  .tabs-panel {
    width: calc(100% - 375px);
  }

  .tab-content {
    padding: 30px 28px;
  }

  .tab-bar .tab {
    line-height: 38px;
    height: 39px;
  }

  .tab-bar .tab.selected {
    height: 40px;
  }

  .tab-content {
    top: 39px;
    height: calc(100% - 39px);
  }

  .project-list {
    top: 38px;
    height: calc(100% - 38px);
  }
  .button-bar-row {
    margin: 20px 0;
  }
  .project-list .project {
    padding: 15px 20px;
  }
  .tab-content ul li {
    padding-left: 50px;
  }
  .tab-content ul li svg {
    left: 18px;
  }
}

@media screen and (max-width: 1280px) {
  .projects-panel {
    width: 345px;
  }
  .tabs-panel {
    width: calc(100% - 345px);
  }
}

@media screen and (max-width: 1166px) {
  body {
    font-size: 12px;
  }
  .projects-panel {
    width: 300px;
  }
  .tabs-panel {
    width: calc(100% - 300px);
  }
  .tag {
    font-size: 11px;
    border-radius: 5px;
    margin: 2px;
    padding: 1px 4px;
    display: inline-block;
  }
}

@media screen and (min-width: 1001px) {
  .mobile-menu-button {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .tab-bar .tab {
    line-height: 48px;
    height: 49px;
  }

  .tab-bar .tab.selected {
    height: 50px;
  }

  .tab-content {
    top: 49px;
    height: calc(100% - 39px);
  }

  body {
    font-size: 18px;
    background-color: #0d1017;
  }
  .projects-panel:not(.open) {
    display: none;
  }
  .projects-panel {
    width: 100%;
    position: absolute;
    height: 100%;
    z-index: 100;
  }
  .tabs-panel {
    width: 100%;
    position: absolute;
    height: calc(100% - 10px);
  }
  .tab-content {
    border-right: none;
    border-left: none;
    border-bottom: none;
  }

  .main-window {
    height: calc(100% - 50px);
    padding: 0;
  }
  span.date-time {
    display: none;
  }

  .title-bar {
    height: 50px;
    line-height: 50px;
    position: relative;
    border-bottom: 1px solid #30383e;
  }

  .mobile-menu-button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }

  .lightbox.vertical {
    height: auto !important;
    width: 100% !important;
    max-height: 100%;
  }

  .lightbox.vertical img {
    width: 100% !important;
    height: auto !important;
  }
}

@media screen and (max-width: 640px) {
  .button-bar a {
    display: block;
  }

  .button-bar {
    display: block;
    width: 100%;
    text-align: center;
  }

  .button-bar a:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid;
  }
}

@media screen and (max-width: 540px) {
  .media-gallery-item {
    width: calc(50% - 16px);
    padding-bottom: 46%;
  }
}

@media screen and (max-width: 400px) {
  .title-bar {
    font-size: 4vw;
  }
}
